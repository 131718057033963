import {stripDiacritics} from '@vantix/functions/isomorphic/stringTools';
import {Select, SelectProps} from 'antd';
import {BaseOptionType, DefaultOptionType} from 'antd/es/select';
import clsx from 'clsx';
import {useMemo} from 'react';
import {UseControllerProps, useController, useFormContext} from 'react-hook-form';

import Typography from '../Typography';

import classes from './index.module.scss';

export type FormSelectProps<ValueType, OptionType> =
    SelectProps<ValueType, OptionType>
    & UseControllerProps;

export default function FormSelect<ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>({
    className,
    disabled,
    ...rest
}: FormSelectProps<ValueType, OptionType>) {
    const {control} = useFormContext();

    const {field: {onBlur, onChange, value, ref}, fieldState: {error}} = useController({
        control,
        ...rest,
    });

    const filterOption = useMemo<(
        searchFor: string, option?: OptionType) => boolean
        >(
        () => (searchFor, option) => stripDiacritics(option?.label).toLowerCase().includes(stripDiacritics(searchFor).toLowerCase()),
        [],
        );

    return (
        <>
            <Select
                filterOption={filterOption}
                {...rest}
                ref={ref}
                className={clsx(classes['FormSelect'], className)}
                disabled={disabled}
                style={{width: '100%'}}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
            />
            {error?.message ? (
                <Typography
                    color="error"
                    style={{marginTop: '4px'}}
                    variant="pSmall"
                >{error.message}</Typography>
            ) : null}
        </>
    );
}
