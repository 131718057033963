import BigNumber from 'bignumber.js';
import MathEvaluator from 'math-expression-evaluator';

const mathEvaluator = new MathEvaluator();
export function calculateMathExpression(expression: string, decimalPlaces?: number): BigNumber {
    try {
        return BigNumber(mathEvaluator.eval(expression, mathEvaluator.tokens, {})).decimalPlaces(decimalPlaces ?? 3);
    }
    catch {
        return BigNumber('0');
    }
}

export function atLeastTwoDecimals(input: BigNumber): string {
    const fixedTwoDecimals = input.toFixed(2);

    return fixedTwoDecimals + input.toString().slice(fixedTwoDecimals.length);
}
