import {faSquareExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {CSSProperties, MouseEventHandler, ReactNode, forwardRef, memo, useEffect, useReducer, useState} from 'react';
import {Blurhash} from 'react-blurhash';

import Spinner from '../Spinner';

import classes from './index.module.scss';

const loadedImages = new Set<string>();

function BlurHashImage({src, blur, className, children, style, onClick}: {src: string; blur?: string; className?: string; children?: ReactNode; style?: CSSProperties; onClick?: MouseEventHandler<HTMLDivElement>}, ref) {
    const [retryNumber, retry] = useReducer(x => x + 1, 0);

    const [loading, setLoading] = useState(!loadedImages.has(src));
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(false);

        const image = new Image();

        image.addEventListener('load', () => {
            loadedImages.add(src);
            setLoading(false);
        });
        image.addEventListener('error', () => {
            if (retryNumber < 3) {
                setTimeout(retry, retryNumber === 2 ? 2000 : 500);
            }
            else {
                setError(true);
            }
        });

        image.src = src;
    }, [retryNumber, src]);

    return (
        <div
            ref={ref}
            className={clsx(classes['BlurHashImage'], {
                [classes['BlurHashImage--loading']]: loading && !error,
            }, className)}
            style={style}
            onClick={(e) => {
                if (error) {
                    retry();
                }
                else {
                    onClick?.(e);
                }
            }}
        >
            {error ? (
                <FontAwesomeIcon color="red" icon={faSquareExclamation} size="xl" />
            ) : (
                <>
                    {blur ? (
                        <Blurhash
                            className={clsx(classes['BlurHashImage__blur'])}
                            hash={blur}
                            height={100}
                            resolutionX={4}
                            resolutionY={4}
                            width={100}
                        />
                    ) : null}
                    <Spinner className={classes['BlurHashImage__spinner']} size="small" />
                    <div
                        key={retryNumber}
                        className={classes['BlurHashImage__image']}
                        style={{backgroundImage: `url('${src}')`}}
                    />
                    {children || null}
                </>
            )}
        </div>
    );
}

export default memo(forwardRef(BlurHashImage));
